<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import ProductService from '@/services/product';
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Banners",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, Multiselect },
  data() {
    return {
      title: "Banners",
      banners: [],
      bannersWeb: [],
      bannerSelected: {},
      
      isLoadingProducts: false,
      products: [],
      departments: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load(hideLoading = false) {
      let loader;

      if (!hideLoading) {
        loader = this.$loading.show();
      }

      try {
        const departmentsResult = await ProductService.getDepartments();
        this.departments = departmentsResult.data;

        const bannersResult = await ProductService.getBanners();
        this.banners = bannersResult.data.map(item => {
          return {
            ...item,
            showOptions: false,
          }
        });

        const bannersWebResult = await ProductService.getBannersWeb();
        this.bannersWeb = bannersWebResult.data.map(item => {
          return {
            ...item,
            showOptions: false,
          }
        });

        if (!hideLoading) {
          loader.hide();
        }
      } catch (err) {
        if (!hideLoading) {
          loader.hide();
        }

        this.$toast.error('Ocorreu um erro ao carregar as imagens');
      }
    },
    uploadBanner() {
      const files = this.$refs.file.files;

      for( var i = 0; i < files.length; i++ ) {
        const index = this.banners.length;

        this.banners.push('temp');

        let file = files[i];
        
        let formData = new FormData();
        formData.append('file', file);

        ProductService.uploadBanner(formData).then(() => {
          this.load(true);

          this.$forceUpdate();

          this.$toast.open('Banner adicionado com sucesso');
        }).catch(() => {
          this.banners.splice(index, 1);
          this.$forceUpdate();

          this.$toast.error('Falha no envio da imagem');
        })
      }      
    },
    uploadWebBanner() {
      const files = this.$refs.fileWeb.files;

      for( var i = 0; i < files.length; i++ ) {
        const index = this.bannersWeb.length;

        this.bannersWeb.push('temp');

        let file = files[i];
        
        let formData = new FormData();
        formData.append('file', file);

        ProductService.uploadWebBanner(formData).then(() => {
          this.load(true);

          this.$forceUpdate();

          this.$toast.open('Banner adicionado com sucesso');
        }).catch(() => {
          this.bannersWeb.splice(index, 1);
          this.$forceUpdate();

          this.$toast.error('Falha no envio da imagem');
        })
      }      
    },
    removeImage() {
      this.$bvModal.hide('modal-remove-image');

      ProductService.removeBanner(this.imageToRemove).then(() => {
        this.load(true);
        this.$toast.open('Banner removido com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao remover o banner');
      })
    },
    loadBannerConfig(banner) {
      this.bannerSelected = banner;
      this.$bvModal.show('modal-config-image');

      if (banner.product) {
        ProductService.getUniqueProducts(banner.product.description).then(res => {
          this.products = res.data;
          this.bannerSelected.product = this.products[0];
        })
      }
    },
    findProducts(term) {
      this.isLoadingProducts = true;

      ProductService.getUniqueProducts(term).then(res => {
        this.isLoadingProducts = false;

        this.products = res.data;
      }).catch(() => {
        this.isLoadingProducts = false;
      })
    },
    configBanner() {
      this.$bvModal.hide('modal-config-image');

      let loader = this.$loading.show();

      ProductService.updateBanner(this.bannerSelected).then(() => {
        this.load(true);
        this.$toast.open('Banner atualizado com sucesso');

        loader.hide();
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao atualizar o banner');
        loader.hide();
      })
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-0">APP</h4>
            <p class="card-title-desc mb-2">Sugestão de resolução: 300x600</p>
            <div class="images">                 
              <div v-for="banner in banners" :key="banner._id" class="image" @mouseover="banner.showOptions = true" @mouseleave="banner.showOptions = false">
                <div v-if="banner !== 'temp' ? true : false" class="image-group">
                  <img :src="banner.image" />

                  <a v-if="banner.showOptions" href="javascript:void(0);" class="action-icon remove-image" v-b-modal.modal-remove-image @click="imageToRemove = banner._id" >
                    <i class="mdi mdi-trash-can font-size-14"></i>
                  </a>

                  <a v-if="banner.showOptions" href="javascript:void(0);" class="action-icon config-image" @click="loadBannerConfig(banner)">
                    <i class="mdi mdi-menu font-size-14"></i>
                  </a>
                </div>

                <div class="new-image" v-else>
                  <!-- <i class="bx bx-image-add"></i>
                  <p>Clique para adicionar uma nova imagem</p> -->
                  <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
                </div>
              </div>                  
              <input type="file" multiple accept="image/*" name="file" ref="file" v-on:change="uploadBanner" />
            </div>   

            <div class="row mt-4 ml-2">
              <div class="col-sm-12">
                <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="$refs.file.click()">
                  <i class="mdi mdi-plus mr-1"></i> Adicionar Banner App
                </button>
              </div>
            </div>                 
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-0">WEB</h4>
            <p class="card-title-desc mb-2">Sugestão de resolução: 250x1000</p>
            <div class="images">                 
              <div v-for="banner in bannersWeb" :key="banner._id" class="image" @mouseover="banner.showOptions = true" @mouseleave="banner.showOptions = false">
                <div v-if="banner !== 'temp' ? true : false" class="image-group">
                  <img :src="banner.image" />

                  <a v-if="banner.showOptions" href="javascript:void(0);" class="action-icon remove-image" v-b-modal.modal-remove-image @click="imageToRemove = banner._id" >
                    <i class="mdi mdi-trash-can font-size-14"></i>
                  </a>

                  <a v-if="banner.showOptions" href="javascript:void(0);" class="action-icon config-image" @click="loadBannerConfig(banner)">
                    <i class="mdi mdi-menu font-size-14"></i>
                  </a>
                </div>

                <div class="new-image" v-else>
                  <!-- <i class="bx bx-image-add"></i>
                  <p>Clique para adicionar uma nova imagem</p> -->
                  <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
                </div>
              </div>                  
              <input type="file" multiple accept="image/*" name="fileWeb" ref="fileWeb" v-on:change="uploadWebBanner" />
            </div> 

            <div class="row mt-4 ml-2">
              <div class="col-sm-12">
                <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="$refs.fileWeb.click()">
                  <i class="mdi mdi-plus mr-1"></i> Adicionar Banner Web
                </button>
              </div>
            </div>               
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-remove-image" title="Excluir Banner" title-class="font-18" centered>
      <p>Confirma a exclusão do banner selecionado?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove-image')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeImage">Excluir</b-btn>
      </div>
    </b-modal>

    <b-modal id="modal-config-image" title="Configurar Banner" title-class="font-18" centered>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <b-form-checkbox v-model="bannerSelected.enableRedirect" switch class="mb-1">
              <label>Habilitar Redirecionamento</label>
            </b-form-checkbox>
          </div>
        </div>
      </div>

      <div class="row" v-if="bannerSelected.enableRedirect">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="user">Título</label>

            <input
              id="user"
              name="user"
              type="text"
              class="form-control"
              v-model="bannerSelected.title"
            />
          </div>
        </div>
      </div>

      <div class="row" v-if="bannerSelected.enableRedirect">
        <div class="col-sm-12">
          <label for="user">Tipo de Redirecionamento</label>
          <div class="form-group">
            <div class="custom-control custom-radio custom-control-inline mr-4 ml-1">
              <input type="radio"
                name="redirectTypeProduct"
                id="redirectTypeProduct"
                class="custom-control-input"
                v-model="bannerSelected.redirectType"
                value="product"
              >
              <label class="custom-control-label" for="redirectTypeProduct">PRODUTO</label>
            </div>

            <div class="custom-control custom-radio custom-control-inline mr-4">
              <input type="radio"
                name="redirectTypeDepartment"
                id="redirectTypeDepartment"
                class="custom-control-input"
                v-model="bannerSelected.redirectType"
                value="department"
              >
              <label class="custom-control-label" for="redirectTypeDepartment">GRUPO</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="bannerSelected.enableRedirect">
        <div class="col-12" v-if="bannerSelected.redirectType === 'product'">
          <label>Produto</label>
          <multiselect
            v-model="bannerSelected.product"
            :options="products"
            :close-on-select="true"
            :clear-on-select="true"
            :loading="isLoadingProducts"
            @search-change="findProducts"
            placeholder="Selecione o produto por nome ou código"
            label="fullDescription"
            track-by="code"
            selectLabel="Enter para selecionar"
            selectedLabel="Selecionado"
            deselectLabel="Enter para remover"
            v-if="bannerSelected.redirectType === 'product'"
          ></multiselect>
        </div>

        <div class="col-12" v-if="bannerSelected.redirectType === 'department'">
          <label>Grupo</label>
          <multiselect
            v-model="bannerSelected.department"
            :options="departments"
            placeholder="Selecione o grupo/departamento"
            label="description"
            track-by="_id"
            selectLabel="Enter para selecionar"
            selectedLabel="Selecionado"
            deselectLabel="Enter para remover"
            v-if="bannerSelected.redirectType === 'department'"
          ></multiselect>
        </div>
      </div>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-config-image')">Fechar</b-btn>
        <b-btn variant="primary" @click="configBanner">Confirmar</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .images {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .image {
      display: flex;
      flex-direction: row;
      align-items: center;

      .image-group {
        flex-direction: column;
        display: flex;
        position: relative;
      }
    }

    img {
      height: 100px;
      margin: 20px 20px 10px 20px;
      min-height: 80px;
    }

    .new-image {
      // border: 2px dashed #a6b0cf !important;
      height: 140px;
      width: 140px;
      border-radius: 6px;
      color: #a6b0cf;
      // font-size: 30px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      align-items: center;
      // padding: 10px;

      p {
        font-size: 10px;
        text-align: center;
        margin: 6px 0px 0px 0px;
      }

      // margin: 20px;
    }

    input[type="file"] {
      display: none;
    }
  }

  .nav-link.disabled {
    color: #e1e1e1;
  }

  .remove-image {
    position: absolute;
    right: 14px;
    background-color: #fff;
    color: red;
    height: 10px;
    width: 10px;
    top: 14px;
    padding: 15px;
    margin: 0px;
    display: flex;
    border-radius: 50%;
    /* line-height: 10px; */
    justify-content: center;
    align-items: center;
  }

  .config-image {
    position: absolute;
    left: 14px;
    background-color: #fff;
    color: #255197;
    height: 10px;
    width: 10px;
    top: 14px;
    padding: 15px;
    margin: 0px;
    display: flex;
    border-radius: 50%;
    /* line-height: 10px; */
    justify-content: center;
    align-items: center;
  }
</style>